<template>
  <div class="box-wrap">
    <div class="tj-box">
      <img class="tj-img" :src="tjData.recommendImage" />
      <div class="tj-info">
        <p class="tj-name">{{ tjData.tombName }}</p>
        <div class="tj-price">
          <p>参考价：</p>
          <span>{{ tjData.referencePrice | setPrice }}万</span>
        </div>
      </div>
      <div class="tj-adr">
        <div class="ly-name" @click="goDetail(lyData)">
          <i>陵园：</i>
          <span>{{ tjData.cemeteryName }}</span
          ><img src="@/assets/img/homerec/tj-r-icon.png" alt="" />
        </div>
        <p v-if="lyData">{{ lyData.regionName }}</p>
      </div>
      <div class="tj-adr">
        <div class="ly-name">
          <i>电话：</i>
          <a @click.stop="telClick" href="tel:4008-122-133">4008-122-133</a>
          <img src="@/assets/img/homerec/tel-icon.png" alt="" />
        </div>
      </div>
    </div>
    <div class="form-wrap" v-if="initFlag">
      <FormModal :code="lyData.code" :startTime="startTime" />
    </div>
    <div class="kf-box">
      <div class="kf-btn" @click="goKf">在线咨询</div>
    </div>
  </div>
</template>
<script>
import { getCDetailApi, collectPointApi } from "@/api/apiList.js"; // collectPointApi,
import { mapGetters } from "vuex";
import { setChannel } from "@/util/setChannel.js";
export default {
  name: "modalForm",
  data() {
    return {
      tjData: {},
      lyData: {},
      startTime: 0,
      initFlag: false,
    };
  },
  components: {
    FormModal: () => import("@/views/homerec/form.vue"),
  },
  filters: {
    setPrice(p) {
      let str = (p / 1000000 + "").split(".");
      if (str.length > 1) {
        str = str[0] + "." + str[1].slice(0, 2);
      } else {
        str = str[0] || 0 + ".00";
      }
      return str;
    },
  },
  activated() {
    document.title = this.$route.query.tombName || "如山选墓";
    setChannel(this);
    setTimeout(() => {
      this.buryingPage(6);
    }, 1000);
    this.startTime = +new Date();
    this.tjData = JSON.parse(JSON.stringify(this.$route.query));
    this.getCDetail();
  },
  created() {},
  computed: {
    ...mapGetters(["pages", "UUID"]),
  },
  methods: {
    goDetail(data) {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push({
          path: `/rec/detail/${this.pages.channel}/${this.pages.subChannel}?code=${data.code}`,
          query: {
            title: data.cemeteryName,
          },
        });
      } else {
        this.$router.push({
          path: `/rec/detail?code=${data.code}`,
          query: {
            title: data.cemeteryName,
          },
        });
      }
    },
    getCDetail() {
      let toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      return new Promise((resolve) => {
        getCDetailApi({
          id: this.tjData.cemeteryId,
        })
          .then((res) => {
            this.lyData = res.data.data;
          })
          .finally(() => {
            resolve();
            toast.clear();
            this.initFlag = true;
          });
      });
    },
    // 埋点
    buryingPage(type) {
      this.collectPointParams = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.activityId,
        eventType: type,
        uuid: this.UUID,
        localUrl: location.href,
      };
      collectPointApi(this.collectPointParams)
        .then(() => {
          console.log("埋点成功");
        })
        .catch((err) => {
          console.log(err, "买点失败");
        });
    },
    telClick() {
      this.buryingPage(3);
    },
    goKf() {
      this.buryingPage(2);
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc51653415422b34fa";
    },
  },
};
</script>
<style lang="scss" scoped>
i {
  font-style: normal
}
.box-wrap {
  padding: 0 0.24rem;
  .tj-box {
    padding: 0.24rem;
    border-radius: 0.1rem;
    .tj-img {
      display: block;
      width: 100%;
      height: 4.6rem;
      margin: 0 auto;
      border-radius: 0.1rem;
    }
    .tj-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.36rem 0 0.2rem;
      .tj-name {
        font-size: 0.36rem;
        font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
        font-weight: 600;
        color: #333333;
        line-height: 1;
      }
      .tj-price {
        display: flex;
        align-items: baseline;
        font-size: 0.32rem;
        font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
        font-weight: 400;
        line-height: 0.34rem;
        p {
          color: #999999;
        }
        span {
          font-family: Alibaba PuHuiTi 2-85 Bold;
          font-weight: 700;
          color: #951e15;
        }
      }
    }
    .tj-adr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        padding-top: 0.14rem;
      }
      .ly-name {
        display: flex;
        align-items: center;
        i {
          font-size: 0.32rem;
          font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
          font-weight: 400;
          color: #333333;
          line-height: 0.45rem;
        }
        span {
          font-size: 0.32rem;
          font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
          font-weight: 400;
          color: #ff9d20;
          line-height: 0.34rem;
          border-bottom: 1px solid rgba(255, 157, 32, 0.5);
        }
        img {
          width: 0.28rem;
          height: 0.28rem;
          margin-left: 0.06rem;
          transform: translateY(-0.02rem);
        }
        a {
          font-size: 0.32rem;
          font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
          font-weight: 500;
          color: #951e15;
          line-height: 0.4rem;
          border-bottom: 1px solid #951e15;
        }
      }
      p {
        padding-right: .44rem;
        font-size: 0.28rem;
        font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
.form-wrap {
  padding: 0 0.24rem;
  padding-top: 0.3rem;
}
.kf-box {
  position: fixed;
  right: 0;
  position: fixed;
  top: 48vh;
  width: 1.46rem;
  height: 1.56rem;
  background: url("~@/assets/img/homerec/kf-icon.png") no-repeat center top;
  background-size: 1.46rem 1.56rem;
  padding-top: 1rem;
  box-sizing: border-box;
  transform: translateY(-50%);
  .kf-btn {
    width: 1.12rem;
    height: 0.4rem;
    margin: 0 auto;
    background: #ff9d20;
    box-shadow: 0px 0.04rem 0.16rem 0px rgba(172, 118, 51, 0.35);
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.22rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.34rem;
  }
}
</style>